import React, { Component } from 'react';
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import PropTypes from 'prop-types';
import ROUTES from "utils/routes";
import { StaticImage } from 'gatsby-plugin-image'

import AUTO_SUGGEST_SIG_NAME from 'utils/graphql/autoSuggest/sigName/sigName';

import AutoSuggestion from "components/autoSuggestion/AutoSuggestion";
import Footer from 'components/footer/FrontFooter';

// Import Bootstrap components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faAngleRight, faPlayCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import RsfSvg from 'components/footer/rsf-dark-short.svg';

import './landing.scss';

const processResponse = response => {
  if (!response.data) { throw Error("Response error"); }
  return response.data.topClientsByFuzzyName; //returns the array of top clients/companies
}

const getSuggestionString = suggestion => suggestion.candidatesClient.clientName; //given a top client/company object, returns the client/company name


class Landing extends Component {

  clickSuggestion = (self, suggestion) => {
    navigate("/query?sigName="+suggestion.candidatesClient.clientName)
  }


  render() {
    return(
      <div id="App">
        <div id="pane">
          <div id="landing">
          <Container>
              <Row>
               <Col lg={8} id="landing-content">
                  <h2>Advancing data science research in interest group politics</h2>
                  <h1>LobbyView</h1>
                  <AutoSuggestion
                  minInputLength={3}
                  maxSuggestions={5}
                  placeholder="Search a company (i.e. Apple Inc.)"
                  clickSuggestion={this.clickSuggestion}
                  processResponse={processResponse}
                  getSuggestionString={getSuggestionString}
                  getQuery={AUTO_SUGGEST_SIG_NAME}
                  icon={<FontAwesomeIcon icon={faSearch} className="fa-2x" color="white"/>}
                  />
                </Col>
              </Row>
        </Container>
        </div>
       </div>

        <div id="database">
          <Container>
            <Row>
              <Col xs={12}  >
                <div className="tile" id="database">
                <Row>
                <Col lg={6} id="database-content">
                  <div className="content">
                    <h3>1,000,000+ Lobbying Reports</h3>
                    <p>Winner of IPES Best New Dataset</p>
                    <a href={ROUTES.QUERY}>Search the Database <FontAwesomeIcon icon={faAngleRight}/></a>
                  </div>
                </Col>

                <Col lg={5}>
                  <StaticImage src='../../images/graph-nobackground.png' alt="data" />
                </Col>
                </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12} lg={6}>
                <div className="tile" id="video">
                  <div className="content">
                    <h3>Introducing LobbyView</h3>
                    <a href="https://www.youtube.com/watch?v=29OMHHuUzUw" target="_blank" rel="noopener noreferrer">Watch the Video <FontAwesomeIcon icon={faPlayCircle}/></a>
                  </div>

                  <StaticImage src='../../images/video.png' alt="video"/>
                </div>

              </Col>
              <Col md={12} lg={6}>
                <div className="tile" id="datasets">
                  <div className="content">
                    <h3>Curated Datasets</h3>
                    <a href={ROUTES.DATA_DOWNLOAD}>Download the Data <FontAwesomeIcon icon={faFileDownload}/></a>
                  </div>

                  <StaticImage src='../../images/datasets-transparent.png' alt="data"/>
                </div>

              </Col>

            </Row>
          </Container>


          <Container id="visualizations">
            <Row>
              <Col>
                <h2>Explore Visualizations</h2>
              </Col>
            </Row>
            <Row>
            {vizes.slice(0,3).map ((viz, i) =>
                <Col sm={12} md={6} lg={4} key={i}>
                  <VizTile {...viz}/>
                </Col>
              )}
            </Row>
            <Row>
            {vizes.slice(3).map ((viz, i) =>
                <Col sm={12} md={12} lg={6} key={i}>
                  <VizTile {...viz}/>
                </Col>
              )}
            </Row>
          </Container>


          <Container>
            <Container id="sponsors">
            <Row>
              <Col>
                <h3>Supported by</h3>
              </Col>
            </Row>
            <Row>
              {sponsors.map((sponsor, i) =>
                <Col md={12} lg={4} key={i} className="sponsor-tile">
                  <div className="sponsor">
                    {sponsor.img}
                    <p>{sponsor.name}</p>
                  </div>
                </Col>
              )}
            </Row>
            </Container>
          </Container>
        </div>

        <Footer />
      </div>
    )
  }
}

export default Landing;

const sponsors = [
  {
    img: <StaticImage src='../footer/mit-transparent.png' alt="Massachusetts Institute of Technology"/>,
    name: "Massachusetts Institute of Technology",
  },
  {
    img: <StaticImage src='../footer/nsf.png' alt="National Science Foundation"/>,
    name: "National Science Foundation",
  },
  {
    img: <img src={RsfSvg} alt={RsfSvg} style={{
      width: "50px",
      height: "100%",
      marginRight: "10px",
      marginTop: "-1em",
    }}/>, //<StaticImage src='../components/footer/rsf-dark-short.svg' alt="Russel Sage Foundation"/>,
    name: "Russel Sage Foundation",
  },
]



type VizType = {
  href: string,
  img: React.ReactNode,
  info: string,
  title: string
}

const vizes = [
  {
    href:"/query?vizTab=revolvingDoor",
    img: <StaticImage src='../../images/revolving-door.png' alt="revolving door"/>,
    info: "See the experiences of lobbyists as they revolve between politics and government.",
    title:"Revolving Door Lobbying",
  },
  {
    href:"/query?vizTab=inHouseOutsourced",
    img: <StaticImage src='../../images/kstreet.png' alt="k street"/>,
    info: "See the distributions of lobbying done in-house and from K-Street lobbying firms.",
    title:"In House vs. K Street Lobbying",
  },
  {
    href:"/query?vizTab=timeSeries",
    img: <StaticImage src='../../images/total-expenditures.png' alt="total expenditures"/>,
    info: "See the the issues being lobbied for over time.",
    title:"Lobbying Spending Over Time",
  },
  // {
  //   href:"/visualizations?vizTab=lobbyingMap",
  //   img: <StaticImage src='../../images/spatial-analysis.png' alt="spatial analysis"/>,
  //   info: "LobbyView provides geo-spatial data according to the principal place of business of interest groups.",
  //   title: "Spatial Analysis",
  // },
  {
    href:"/query?vizTab=wordCloud",
    img: <StaticImage src='../../images/content-analysis.png' alt="content analysis"/>,
    info: "Researchers can conduct text analysis of contents of lobbying.",
    title: "Content Analysis",
  },
  {
    href:"/visualizations?vizTab=network",
    img: <StaticImage src='../../images/networks.png' alt="networks"/>,
    info: "Lobbied legislative bills form unique network data that describe legislative politics.",
    title: "Network Analysis",
  }
]

const VizTile = (props:VizType) => {
  return(
    <div className="viztile">
      <Link className="noStyle" to={props.href}>
        <h4>{props.title}</h4>
        {props.img}
        <div className="overlay"><div id="info">{props.info}</div></div>
      </Link>
    </div>
  )
}

VizTile.propTypes = {
  href: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  info: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
